import React from "react";
import {Route , Routes} from 'react-router-dom'
import Dashboard from "./Dashboard";
import Invitation from "./Invitation";
import Cash from './Cash';
import Ticket from "./Ticket";
import Banner from "./Banner";
import Survey from "./Survey";
import Policy from "./Policy";
import Login from "./Login";
import Register from "./Register";
import RecovetyPassword from "./RecoveryPassword";
import Cart from "./Cart";
const Content = ()=>{
    return (
            <>
                   <Routes>
                       <Route path="/RecovetyPassword" element={<RecovetyPassword/>} />
                       <Route path="/login" element={<Login/>}/> 
                       <Route path="/register" element={<Register/>}/> 
                       <Route path="/" element={<Dashboard/>}/>
                       <Route path="/Cash" element={<Cash/>}/>
                       <Route path="/invitation" element={<Invitation/>}/>
                       <Route path= "/cart" element={<Cart/>} />
                       <Route path="/ticket" element={<Ticket/>} />
                       <Route path="/Survey" element={<Survey/>} />
                       <Route path="/policy" element={<Policy/>} />
                   </Routes>
            </>
    )
}
export default Content