import React, {useEffect, useState} from "react";
import Menu from "./Menu";
import Banner from "./Banner";
import bannerWelcome from "./images/welcome.jpg";
import "./style/dashbord.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {faVoicemail} from "@fortawesome/free-solid-svg-icons";
import {Routes, Route, useNavigate} from 'react-router-dom';
import {configAxios} from "./configAxios";
const Header = ()=>{
    const [users , setUser] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    useEffect(()=>{
        if (!(localStorage.getItem('token'))){
            navigate('/login')
        }
        if(isLoading === false){
            getInfoUser() 
        }
    },[])

    // let clearTimeOut=setTimeout(getInfoUser, 2000)
    //    if(isLoading === false){
    //     clearTimeout(clearTimeOut)
    //    } 
    function getInfoUser()
    {
        setIsLoading(true)
       configAxios.post('/get-info').then(res =>{
        
        setIsLoading(false)
            setUser(res.data.user)
        }).catch((e)=>{
            setIsLoading(false)     
        })
    }
    return (
         <>
            <Banner/>
            <div className="dashboard_div">
            <Menu/>
            <div className="info_user">
                <h6>{users.first_name}      {users.last_name} خوش آمدید</h6>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faInfo}/>
                        اطلاعات
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faPhone}/>
                        {users.phone}
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faVoicemail}/>
                        {users.email}
                    </li>
                </ul>
                <p>ورود شما به معنای پذیرش قوانین و مقررات است.</p>
            </div>
            <div className="banner-image">
                <img src={bannerWelcome}  alt="" width={'300px'} height={'150px'} />
            </div>
        </div>
         </>
        
    )
}

export default Header;