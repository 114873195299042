import React from "react";
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import Menu from "./Menu";
import Content from "./Content";
const App = ()=>{
    return(
       <BrowserRouter>
        <div>
            <Content/>
        </div>
       </BrowserRouter>
    )
}

export  default App;