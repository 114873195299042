import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Routes, Route, useNavigate} from 'react-router-dom';
import "./style/auth.css"
import {configAxios} from "./configAxios";
import Swal from "sweetalert2";
const Register = () => {
  const  [data , setData] = useState({
      'email':'',
      'password':'',
      'password_confirmation':'',
      'first_name':'',
      'last_name':'',
      'phone':'',
      'invitation_code':""
  })
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'origin': 'https://blockquaint.com',
        'Access-Control-Allow-Origin': '*'
    }
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false) 
  const handelRegister = (e) =>{
       e.preventDefault();
       setIsLoading('true')
       configAxios.post('/register',data,headers).then(res =>{
        console.log(res);
           if (res.status === 200) {

            setIsLoading('false')
               navigate('/login')
               Swal.fire('ثبت نام با موفقیت انجام شد')
           }
       }).catch((e)=>{
           if (e.response.status === 422){
               setIsLoading('false')
              Swal.fire('مشکلی رخ داده است');
           }
       })
    }
  return (
        <>
            {isLoading === true ? (
                <h2 className="text-center text-info mt-5">لطفا منتظر بمانید ....</h2>               
            ) : (
              <div className="back" style={{ "min-height": "100vh" }}>
               
							<h6 class="mb-0 pb-3 links-reg"><span><a href="">ثبت نام</a></span><a href="login"><span>ورود</span></a></h6> 
              <Form onSubmit={handelRegister} className="mt-5 back-rejester" style={{ "width": "30%","margin": "0 auto", "opacity": "0.8" , "padding" : "1rem"}} >
              <Col lg={12} style={{ margin:"0 auto" }}>
              <Form.Group className="mb-3" controlId="name">
               <Form.Label>نام</Form.Label>
               <Form.Control type="text" required id="name" placeholder="نام خود را وارد نمایید ..."  value={data.first_name} onChange={(e)=>setData({...data,first_name: e.target.value})} />
             </Form.Group>
              </Col>
              <Col lg={12} style={{ margin:"0 auto" }}>
              <Form.Group className="mb-3" controlId="last_name">
               <Form.Label> نام خانوادگی</Form.Label>
               <Form.Control required type="text" id="last_name" placeholder="نام خانوادگی خود را وارد نمایید ..." value={data.last_name} onChange={(e)=>setData({...data,last_name: e.target.value})} />
             </Form.Group>
              </Col>
              <Col lg={12} style={{ margin:"0 auto" }}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
               <Form.Label>آدرس ایمیل</Form.Label>
               <Form.Control  required type="email" placeholder="ایمیل خود را وارد نمایید ..." value={data.email} onChange={(e)=>setData({...data,email: e.target.value})} />
             </Form.Group>
              </Col>
        
               <Col lg={12} style={{ margin:"0 auto" }}>
               <Form.Group className="mb-3" controlId="formBasicPassword">
               <Form.Label>رمز عبور</Form.Label>
               <Form.Control type="password" required placeholder="رمز عبور خود را وارد نمایید ..." value={data.password} onChange={(e)=>setData({...data,password: e.target.value})} />
             </Form.Group>
               </Col>
   
               <Col lg={12} style={{ margin:"0 auto" }}>
               <Form.Group className="mb-3" controlId="formBasicPassword">
               <Form.Label>تکرار رمز عبور</Form.Label>
               <Form.Control type="password" required placeholder="تکرار رمز عبور خود را وارد کنید ..." value={data.password_confirmation} onChange={(e)=>setData({...data,password_confirmation: e.target.value})} />
             </Form.Group>
              <Col/>
              
               <Col lg={12} style={{ margin:"0 auto" }}>
               <Form.Group className="mb-3" controlId="invation_code">    
               <Form.Label>کد معرف</Form.Label>
               <Form.Control type="text"  placeholder="در صورت داشتن کد معرف آن را وارد نمایید..." value={data.invitation_code} onChange={(e)=>setData({...data,invitation_code: e.target.value ? e.target.value : 1})} />  
              </Form.Group>
               </Col>
             <Button variant="primary" type="submit">
               ثبت نام
             </Button>
               </Col>
               <Col lg={12} style={{ margin:"0 auto" }} className="pt-3">          
          {/* <a href="login" style={{ color:"white" , font_size:"10px" }}>ثبت نام کرده اید ؟؟</a> */}
          <Form.Label for="policy-privicy" className="col-lg-11">ثبت نام شما به معنای پذیرش قوانین و مقررات بلک کوئینت است</Form.Label>
           <input className="col-lg-1" id="policy-privicy" type="checkbox" style={{ position:"static" }} />

           </Col>
           </Form>
                   </div>
            )}
        </>
  );
};

export default Register;
