import React, { useEffect } from "react";
import Menu from "./Menu";
import Course_Table from "./Course_Table";
import './style/dashbord.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import bannerWelcome from './images/welcome.jpg'
import Header from "./Header";
// useEffect(()=>{
    
// },[])
const Dashboard = ()=>{
   return(
          <>
               
              <Header/>
              <Course_Table/>
          </>
   )
}

export default Dashboard;