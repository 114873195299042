import React, { useEffect, useState  } from "react";
import Header from "./Header";
import Col from "react-bootstrap/Col";
import "./style/invitation.css"
import Table from "react-bootstrap/Table";
import moment from 'jalali-moment'

import { configAxios } from "./configAxios";
const Invitation = () =>{
    const [getUserInviation , SetUserInviation] = useState([]);
    const [getInviation , SetInviation] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (!(localStorage.getItem('token'))) {
            navigate('/login')
        }
            
        if(isLoading === false){
            getInfoUser() 
        }
        async function copyTextToClipboard(text) {
            if ('clipboard' in navigator) {
              return await navigator.clipboard.writeText(text);
            } else {
              return document.execCommand('copy', true, text);
            }
          }
    },[]);
    function getInfoUser()
    {
        setIsLoading(true)
            configAxios.post('/get-inviation').then(res=>{
                
           SetUserInviation(res.data.user_invitation);   
           SetInviation(res.data.invitation_code);
       }).catch(e=>{
           console.log(e)
       })
          
    }
    function handelCopy(text){
        navigator.clipboard.writeText(text)
        // alert('کد معرف شما کپی شد')
        // setTimeout(()=>{
        //     setIsCopied(true)
        // },500)
    }
    return (
              <>
                  <Header/>
                   <Col sm={12}>
                       <div className="d-flex invitation_div_top">
                           <h5 className={"me-4"}>جوایز ویژه</h5>
                           <Col sm={6}>
                               <div className="invitation_div_top_right">
                                <p style={{ "paddingRight": "15px" }}>با اولین دعوت : با دعوت  کردن از دوستات 2 تتر دریافت کنید.</p>
                                <p style={{ "paddingRight": "15px" }}>پنجمین دعوت : وقتی 10 تتر جمع کنی، میتونی یک دوره رایگان دریافت کنی.</p>
                                <p style={{ "paddingRight": "15px" }}>به ازای هر دعوت 2 تتر دریافت میکنی.</p>
                                <p style={{ "paddingRight": "15px" }}>امکان تسویه حساب بعد از هر دعوت وجود ندارد و جوایز صرفا برای استفاده از امکانات سایت است.</p>
                                <p style={{ "paddingRight": "15px" }}>استفاده از جوایز کاملا اختیاری است</p>
                                 
                               </div>
                           </Col>
                           <Col className={5} >
                                <div className={"card_code_invite"}>
                                    <p>کد دعوت شما</p>
                                    <span>{getInviation['invitation_code']}</span>
                                </div>
                                <div className={"btn_invite_copy"}>
                                    {/* <button>کپی متن دعوتنامه</button> */}
                                    <button onClick={handelCopy(getInviation['invitation_code'])}>کپی کنید !</button>
                                </div>
                               <a style={{ textDecoration: "none", textAlign: "center",lineHeight: "2" }} href="https://blockquaint.com/video/آموزش-پنل.mp4" className={"btn-show-edu"}>آموزش استفاده از متن</a>
                           </Col>
                       </div>
                       <div className="d-flex invitation_div_bottom">
                           <h5 className={"me-4"}>با یک تیر سه نشان بزن</h5>
                           <Col sm={6}>
                               <div className="invitation_div_top_right">
                                <p style={{ "paddingRight": "15px" }}>
                                    کد دعوت خود را برای دوستان خود ارسال کنید و به نشان اول 2 تتر به ازای هر ثبت نام از ما دریافت کنید
                                </p>
                                <p style={{ "paddingRight": "15px" }}>
                                    نشان دوم : فقط با دعوت دوستات میتونی از امکانات سایت رایگان استفاده کنید
                                </p>
                                <p style={{ "paddingRight": "15px" }}>
                                    نشان سوم : وقتی دوستات ثبت نام کنن توهم سود میکنی.
                                </p>
                               </div>
                           </Col>
                           <Col className={5}>

                           </Col>
                       </div>
                       <div className="col-12 div_table" style={{ padding: "40px" }}>
                           <h5 className={"me-3"}>تعداد افراد</h5>
                           <Table responsive>
                               <thead>
                               <tr>
                                   <th>شماره</th>
                                   <th>نام</th>
                                   <th>تاریخ ثبت </th>
                                   <th>امتیاز</th>
                               </tr>
                               </thead>
                               <tbody>
                                
                               {getUserInviation.map((u,key) =>(
                              <tr className="bg-table-custom">
                              <td>{key+1}</td>
                              <td>{u.first_name}   {u.last_name}</td>
                              <td>
                              {moment(u.created_at, 'YYYY-MM-DD').locale('fa').format('YYYY-MM-DD')}
                              </td>
                              <td>{(key+1)*2} تتر</td>
                              
                          </tr>
                              ))}
                               </tbody>
                           </Table>
                       </div>
                   </Col>

              </>
           )
};

export default Invitation;