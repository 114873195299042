import React, {useEffect} from "react";
import Header from "./Header";

const Policy = () => {
    useEffect(() => {
        if (!(localStorage.getItem('token'))) {
            navigate('/login')
        }
    });
    return (
          <>
              <Header/>
               <section style={{ margin:"0 auto",padding:"1rem", backgroundColor:"#1D1D1D" }} className="mt-5 col-11">
               <h5 class="text-white" style={{"margin-right": "18px"}}>توافق نامه مدیریت سایت و کاربران</h5>
                 <p class="text-white" style={{"margin-right": "18px", "text-align": "justify",}}>
                     کلیه کاربرانی که قصد عضویت و خریداری از سایت بلک کوئینت را دارند، باید مفاد این توافق‌نامه را کامل مطالعه و سپس اقدام به عضویت و خرید از سایت کنند. عضویت در سایت و یا خرید از آن به منزله مطالعه و قبول شرایط خرید و عضویت و قوانین و مقررات مربوطه است . 
تخلف کاربر از هریک از تعهدات موضوع این قرارداد درصورت تشخیص مدیریت و تیم فنی و تیم پشتیبانی  منجر به مسدودی کاربر می باشد و کاربر حق اعتراض نداشته و حق شکایت قضایی برای مدیر وبسایت باقی میماند.

                 </p>
                 <hr class="text-white"/>
                 
                 <p class="text-white" style={{"margin-right": "18px", "text-align": "justify",}}>
                     

1.کاربران سایت حق دانلود فیلم ها  یا نشر انها و سایر محتویات سایت  را ندارند
<br/>
2.کپی برداری از طراحی و قالب سایت ممنوع است.
<br/>
3.اسکرین شات از اطلاعات سایت  و کپی گرفتن از اطلاعات سایت درصورت نشر ان ممنوع است
<br/>
4.کاربر حق ندارد حساب کاری خود را در اختیار دیگران بگذراد
<br/>
5.هرگونه رونوشت و کپی از مطالب جهت انتشار ممنوع است.
<br/>
6. هرگونه سعی در ایجاد اختلاف در وبسایت مانند حملات سایبری به هرنوع  از قبیل DDOS و ... قابل پیگیری است.
<br/>
7.منابع رسمی صرفا از طریق سایت BlockQuaint.com و سایر منابعی که توسط سایت معرفی میشود معتبر می باشد . درغیر اینصورت اظهارات ، فروش و بسته های اموزشی و محتوایی  در سایر سایت ها که به نام مجموعه بلک کوئینت ارائه میشود مورد تایید نمی باشد و قابل پیگیری است.
<br/>
8.منظور از پشتیبانی :  صرفا جهت تبیین و پاسخ به سوالات آموزشی مرتبط با مطالب ارائه شده در دوره در مدت زمان تعیین شده  می باشد. محتوای ارائه شده، صرفا جنبه اموزشی داشته و جنبه سیگنال ندارد و هرگونه اعمال و پیاده سازی این اموزش ها در صورتی که منجر به زیان یا خسارت به کابر شود مسئولیتی برعهده تیم فنی ، پشتیبانی و مدیران وبسایت نیست. همچنین در صورت ارایه تحلیل یا سیگنال پیشنهادی در هر یک از قسمت های سایت یا دوره های آموزشی مسئولیت بررسی و استفاده از آن بر عهده کاربر میباشد.
<br/>
9.درصورتی که به هردلیل از قبیل مشکل در سرور یا حملات سایبری ،  سایت از کار بیفتد یا محتوای سایت از بین برود حق استفاده شما کماکان محفوظ میماند و تیم فنی سایت به سرعت سایت را بازیابی و محتوا را بارگزاری میکنند.
<br/>
10.مدت زمان پشتیبانی از زمان اعلامی شروع شده و صرفا محدود به  زمان اعلامی ست و هیچ عذری جهت تمدید ان به جهت عدم استفاده ی کاربر پذیرفته نیست . مگر به صلاحدید مدیریت سایت
<br/>
11.اگر نیاز به ، به روز شدن و تغییر اطلاعات کاربری باشد کاربر موظف به تکمیل اطلاعات خواسته شده می باشد.
<br/>
12.مسئولیت صحت اطلاعات ارائه شده توسط  کاربر برعهده کاربر بوده و درصورت وجود ارائه هرگونه اطلاعات کذب یا خلاف واقع مسئولیت قضایی برعهده ی وی میباشد.
<br/>
13.هرگونه توهین ، تهدید ، تهمت و سایر گفتارهای محرمانه علیه پشتییان و سایر اعضای تیم اموزشی ممنوع و قابل پیگیری است.
<br/>
14.اگر قوانین کشوری یا جهانی منجر به نسخ شدن یا بی استفاده ماندن محتویات اموزشی گردد هیچ مسئولیتی برعهده مدیریت وبسایت نیست.
<br/>
15. کاربر نباید در سایت ( تالار گفت‌وگو و یا هر قسمت دیگر) اظهاراتی (Comment) را بگذارد و‌ یا اقدامی نماید که مبین نقض حقوق اشخاص دیگر باشد و یا عمل مجرمانه‌ای را به دنبال داشته باشد. همچنین کاربران نباید در خصوص امور مذهبی و سیاسی در این سایت اظهار نظر کنند. چنانچه هر یک از کاربران این ماده را رعایت نکنند، بلافاصله از سایت اخراج شده و چنانچه خریدی انجام داده باشند، وجه واریزی به آنان مسترد نمی شود. همچنین کاربر (عضو) حق استفاده از این سایت را از دست می‌دهد
<br/>
16.مدیریت هرزمان میتواند به صلاحدید تمام یا بخشی از قوانین را تغییر دهد و درصورت اعمال به اطلاع کاربر میرسدو کاربر موظف به رعایت ان است  . قبول قوانین اولیه به منزل قبول تمامی تغییرات بعدی درقوانین میباشد.
<br/>
17.دوره های آموزشی درج شده با هدف آموزش بازارهای مالی و آموزش تحلیل آن بازار است و مسئولیت و هرگونه ریسک فعالیت در آن به عهده شخص است.
<br/>
18.خرید و فروش و نگهداری ارزهای دیجیتال بر اساس قوانین مصوب هر کشور می باشد.
<br/>
19.مسئولیت وبسایت صرفا در حدود متن این قوانین می باشد و خارج این توافق مسئولیتی برعهده مدیر وبسایت نیست.
<br/>
20.تمامی محتوای سایت صرفا مربوط به آموزش و تحلیل بازارهای مالی می باشد و خرید و فروش ارزهای دیجیتال توسط کاربران بر اساس قوانین مصوب هر کشور می بایست انجام شود و این سایت و مجموعه آموزشی بلک کوئینت مسئولیتی در قبال تغییرقوانین کشور ها یا عدم التزام کاربران به قوانین مصوب ندارد.
<br/>
21. متعهد می شوم که درصورت نیاز به احراز هویت در سایت، مدارک واقعی مربوط به خود را بارگذاری کنم.
<br/>
22. صرف عضویت در سایت دلیل بر قبول و پذیرش مفاد موافقتنامه بوده و خلاف آن به هیچ وجه پذیرفته نیست.
<br/>
23. کاربر باید رمز عبور و نام کاربری خود را حفظ نموده و چنانچه آن را فراموش کند، می‌تواند برای اخذ کلمه عبور جدید اقدام کند.
<br/>
24. تنها اشخاص حقیقی حق عضویت در سایت را دارند.کاربر باید آدرس پست الکترونیک خود را به همراه سایر اطلاعات درخواستی به‌طور صحیح اعلام نموده و مسئولیت ثبت اشتباه و یا خلاف واقع بر عهده شخص کاربر است.
<br/>
25. عضویت در سایت شرکت رایگان است.
<br/>
26. به دلیل آنکه در برخی از موارد احراز هویت خریدار از طریق نام و نام خانوادگی ارائه شده در سایت امکان‌پذیر است، مسئولیت هرگونه مغایرت مشخصات فوق با هویت واقعی عضو بر عهده خود افراد بوده و شرکت هیچ‌گونه مسئولیتی نسبت به مغایرت‌های احتمالی ندارد.
<br/>
27. کاربر، مسئول حفظ و نگهداری تمام تلفن‌ها، سخت‌افزار کامپیوتر و سایر ابزار ضروری برای دسترسی و استفاده از این سایت و تمام هزینه‌های مرتبط به آن است و شرکت تخفیف داره مسئول صدمات ناشی از ابزار کاربر نمی‌باشد
<br/>
28. افراد بالای 18 سال تمام حقوق عضویت و انجام معامله (خرید) را در سایت شرکت دارند.
<br/>
29. عضویت برای افراد بین 15 تا 18 سال با اجازه ولی و یا قیم امکان‌پذیر و معتبر است. افراد زیر 15سال، حق عضویت در تخفیف داره را ندارند.
<br/>
30. رعایت حکم این ماده و صحت و سقم ارائه اطلاعات بر عهده مشتری (عضو) بوده و شرکت هیچ‌گونه مسئولیتی در این خصوص ندارد.
<br/>
31.  کاربر عضو، پس از عضویت و اخذ رمز و کلمه عبور، حق خرید از سایت را دارد.
<br/>
32. منظور تعمیرات دوره‌ای این سایت به‌طور موقت غیرقابل دسترس می‌شود و کاربران حق اعتراض ندارند.
<br/>
33.  شرکت این حق را داشته که در هر زمان کلیه فعالیت‌های سایت را متوقف نموده و با پرداخت باقی مانده حساب اعضا کلیه فرآیندهای سایت و فعالیت‌های مربوطه را به‌طور دائمی متوقف نماید و کاربر عضو حق هیچگونه اعتراضی نسبت به این امر را ندارد
<br/>
34. شرکت از کلیه اطلاعات کاربران عضو اعم از اطلاعات دریافتی و ارسالی، (Backup) تهیه نموده و از آن نگهداری می‌کند تا در صورت بروز هرگونه خطری برای یک نسخه، نسخه دیگر مصون بماند.

                 </p>
               </section>
          </>
    )
};

export default Policy