import React, { useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css'
import moment from 'jalali-moment'
import Table from 'react-bootstrap/Table';
import './style/dashbord.css';
import { configAxios } from "./configAxios";

const Course_Table = () => {
    const [getCourse , SetCours] = useState([]);
    useEffect(()=>{
        configAxios.post('/getCart').then(res =>{
          SetCours(res.data.cart);
        }).catch(e =>{
            // console.log(e)
        })
    },[])
    return (
           <div className="col-12 div_table" style={{padding: "40px" }}>
               <Table responsive>
                  <thead>
                          <tr>
                              <th>شماره</th>
                              <th>نام</th>
                              <th>تاریخ</th>
                              <th>وضعیت</th>
                          </tr>
                  </thead>
                   <tbody>
                   {getCourse.map((u,key) =>(
                          <tr className="bg-table-custom">
                              <td>{key+1}</td>
                              <td>{u?.episode?.title}</td>
                              <td>
                              {moment(u.created_at, 'YYYY-MM-DD').locale('fa').format('YYYY-MM-DD')}
                                  
                              </td>
                              <td>{u.status == 0 ? 'در حال انتظار' : 'تایید شده'}</td>
                          </tr>
                      ))}
                   </tbody>
               </Table>
           </div>
    )
};

export default Course_Table;