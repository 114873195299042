import React from "react";
import image from './images/photo1703015407.jpeg'
const Banner = () => {

    return (
        <div style={{backgroundImage:`url(${image})`,backgroundSize:"cover",backgroundPosition:"center center",backgroundRepeat:"no-repeat",width:'100%',height:'200px'}}>

        </div>
    )
};

export default Banner;