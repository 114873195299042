import React, { useEffect, useState  } from "react";
import Header from "./Header";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import cart from "./images/cart.png";
import Table from 'react-bootstrap/Table';
import { configAxios } from "./configAxios";
import moment from 'jalali-moment'
const Cash = ()=>{
    const [getCatch , setCatch]=useState([]);
    const [getCode , setCode]=useState([]);
    const [getInvition , setInvition]=useState([]);
    useEffect(()=>{
        configAxios.get('/getCatch').then(res =>{
            setCatch(res.data.user.catch);
            setCode(res.data.user.user_code);
          }).catch(e =>{
              // console.log(e)
          })

          configAxios.get('/getInvition').then(res =>{
            setInvition(res.data.user)
          }).catch(e =>{
              // console.log(e)
          })
    });
  
    const [data , setData] = useState({
            'price': '',
            'bag_money':'',
            'network':''
        })
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'origin': 'https://blockquaint.com',
            'Access-Control-Allow-Origin': '*'
        }
        const handleCatch = async (e)=>{
            e.preventDefault();
            configAxios.post('/give-catch',data,headers).then(res =>{
                if (res.status === 200) {
                    alert('درخواست شما با موفقیت ثبت گردید')
                }
            }).catch((e)=>{
             
            })
        }

        function handelCopy(text){
            navigator.clipboard.writeText(text)
        }
    return (
        
        <>
         <Header/>
         <div className="d-flex invitation_div_top">
                           <h5 className={"me-4"}>کسب درآمد</h5>
                           <Col sm={6}>
                               <div className="invitation_div_top_right" style={{ height:"260px",top:"3px" }}>
                                <p style={{ "paddingRight": "15px" }}>1. کد دعوت خود را برای دوستان خود ارسال کنید به ازای هر خرید استراتژی تا 20% از مبلغ را از ما دریافت کنید</p>
                                <p style={{ "paddingRight": "15px" }}>2. میتوانی با دعوت از دوستان سرمایه اولیه  برای شروع تریدر شدنت را از ما بگیری</p>
                                <p style={{ "paddingRight": "15px" }}>کارمزد برداشت از مبلغ درخواستی کسر می شود</p>
                                <p style={{ "paddingRight": "15px" }}>امکان تسویه حساب بعد از هر دعوت وجود ندارد و حداقل مبلغ برای درخواست برداشت20 تتر است</p>
                                <p style={{ "paddingRight": "15px" }}>برداشت مبلغ کاملا اختیاری است.</p>
                                 
                               </div>
                           </Col>
                           <Col className={5} >
                                <div className={"card_code_invite"}>
                                    <p>کد دعوت شما</p>
                                    <span>{getCode}</span>
                                </div>
                                <div className={"btn_invite_copy"}>
                                    {/* <button>کپی متن دعوتنامه</button> */}
                                    <button onClick={handelCopy(getCode)}>کپی کنید !</button>
                                </div>
                               <a style={{ textDecoration: "none", textAlign: "center",lineHeight: "2" }} href="https://blockquaint.com/video/آموزش-پنل.mp4" className={"btn-show-edu"}>آموزش استفاده از متن</a>
                           </Col>
                       </div>

                     
                       <div className="invitation_div_top" style={{ backgroundColor:"#1D1D1D",padding:"1rem",marginTop:"5rem" }}> 
                      <form onSubmit={handleCatch}> 
                     <Col sm={12} className="email-file">
                       <Col sm={6}>
                        <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlInput1">
                         
                            <Form.Control required type="number" className="email"  name="number" max={20} value={data.price} onChange={(e)=>setData({...data,price: e.target.value})} placeholder="مبلغ مورد نظر را وارد نمایید."/>
                        </Form.Group>
                       </Col>
                       <Col sm={6}>
                        <Form.Group className="mt-4" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" required name="bag_money" placeholder="آدرس کیف پول خود را وارد نمایید." value={data.bag_money} onChange={(e)=>setData({...data,bag_money: e.target.value})} className="img_shot"/>
                        </Form.Group>
                       </Col>
                    </Col>
                    
                    <Col sm={12}>
                        <Col sm={6}>  
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Select className="course_name" style={{marginRight: "7%",width: "66%"}} id="select_course"  required aria-label="Default select example" value={data.network} onChange={(e)=>setData({...data,network: e.target.value})} name="network">
                            <option>شبکه مد نظر خود را انتخاب کنید</option>    
                            <option value={"BEP20"}>BEP 20</option>    
                            <option value={"TRC20"}>TRC 20</option>    
                            </Form.Select>
                        </Form.Group>
                        </Col>
                        
                    </Col>
                    <button type="submit"  className="mb-3 btn submit-course btn-warning btn-block">ثبت درخواست</button>
                    </form>
                    <div style={{ backgroundColor:'#F5A100',width:"35%",position:"relative",right:"59%",borderRadius:"5px"}}>موجودی کیف پول شما : {getCatch} $</div>
                    </div>
                    <div className="col-12 div_table" style={{padding: "40px" }}>
               <Table responsive>
                  <thead>
                          <tr>
                              <th>شماره</th>
                              <th>نام</th>
                              <th>تاریخ</th>
                          </tr>
                  </thead>
                   <tbody>
                   {getInvition.map((u,key) =>(
                          <tr className="bg-table-custom">
                              <td>{key+1}</td>
                              <td>{u?.first_name} {u?.last_name}</td>
                              <td>
                              {moment(u.created_at, 'YYYY-MM-DD').locale('fa').format('YYYY-MM-DD')}
                                  
                              </td>
                              {/* <td>{u.status == 0 ? 'در حال انتظار' : 'تایید شده'}</td> */}
                          </tr>
                        ))} 
                   </tbody>
               </Table>
           </div>
        </>
    )
}

export default Cash;