import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Routes, Route, useNavigate} from 'react-router-dom';
// import "./style/auth.css";
// import "./js/auth.js"
import {configAxios} from "./configAxios";

const Login = () => {
    const [data , setData] = useState({
        'email': '',
        'password':''
    })
    const [isLoading, setIsLoading] = useState(false)
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'origin': 'https://blockquaint.com',
        'Access-Control-Allow-Origin': '*'
    }
    const navigate = useNavigate();
    const handleLogin = async (e)=>{
        e.preventDefault();
        setIsLoading(true)
        configAxios.post('/login',data,headers).then(res =>{
            if (res.status === 200) {
              setIsLoading(false)
                navigate('/')
                localStorage.setItem('token',res.data.token)
            }
        }).catch((e)=>{
          setIsLoading(false)
                // alert(e.response.data)
        })
    }
  return (
      <>
         {isLoading == true ? (
           <h2 className="text-center text-info mt-5">لطفا منتظر بمانید ....</h2>
         ) : (
      
             
	<div class="back">
		<div class="log-sign">
		<a href="https://front.codes/" class="logo" target="_blank">
		</a>
		<div class="section">
				<div class="row full-height justify-content-center">
					<div class="col-12 text-center align-self-center py-5">
						<div class="section pb-5 pt-5 pt-sm-2 text-center">
							<h6 class="mb-0 pb-3 links-reg-log"><span><a href="register">ثبت نام</a></span><span>ورود</span></h6>
							<label for="reg-log"></label>
							<div class="card-3d-wrap mx-auto">
								<div class="card-3d-wrapper">
									<div class="card-front">
										<div class="center-wrap">
										  <form onSubmit={handleLogin}>
                      <div class="section text-center">
												<h4 class="mb-4 pb-3">ورود</h4>
												<div class="form-group">
													<input type="email" name="logemail" class="form-style"
														placeholder="ایمیل خود را وارد کنید" id="logemail" autocomplete="off" value={data.email} onChange={(e)=>setData({...data,email: e.target.value})} />
													<i class="input-icon uil uil-at"></i>
												</div>
												<div class="form-group mt-2">
													<input type="password" name="logpass" class="form-style"
														placeholder="رمز عبور خود را وارد کنید" id="logpass" value={data.password} onChange={(e)=>setData({...data,password: e.target.value})} autocomplete="off"/>
													<i class="input-icon uil uil-lock-alt"></i>
												</div>
												<button type="submit" class=" btn-auth btn mt-4 ">ورود</button>
												
											<p class="mb-0 mt-4 text-center"><a href="RecovetyPassword" class="link">رمز عبور خود را فراموش کرده اید؟</a></p>
											<label for="accept" class="agreement-p">ورود شما به معنای پذیرش <a href="https://blockquaint.com/policy">قوانین</a>  و <a href="https://blockquaint.com/privacy">حریم خصوصی </a>بلک کوئینت است</label>		
										</div>
                      </form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
			
			</div>
		</div>
		</div>
	</div>
      
         )}
      </>
  );
};

export default Login;
