import React, {useEffect, useState} from "react";
import Header from "./Header";
import "./style/Ticket.css";
import {Routes, Route, useNavigate} from 'react-router-dom';
import Col from "react-bootstrap/Col"
import Form from 'react-bootstrap/Form';
import Table from "react-bootstrap/Table";
import {configAxios} from "./configAxios";
import Swal from "sweetalert2";
const Ticket = () => {
    const [data , setData] = useState({
        'title':'',
        'text':'',
        'priority':''
    });
    const [ticket , setTicket]=useState([]);
    const ticketHandel = (e)=>{
        e.preventDefault();
        configAxios.post('/ticket',data).then(res =>{
            if (res.status === 200){
                Swal.fire('تکیت شما با موفقیت ارسال شد')
            }
        }).catch((e)=>{
            Swal.fire('مشکلی رخ داده است')
        })
    }
    const navigate = useNavigate
    useEffect(()=>{
        if (!(localStorage.getItem('token'))){
            navigate('/login')
        }
        configAxios.get('/get-ticket').then(res=>{
            setTicket(res.data.message)
        }).catch((e)=>{

        });
    })
    return (
        <>
            <Header/>
            <Col sm={12}>

                <div className="div_form_ticket">
                    <div className="form-ticket">
                        <Form onSubmit={ticketHandel}>
                            
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>عنوان تیکت</Form.Label>
                                <Form.Control type="text" required name="title" style={{ border:"none" }} placeholder="عنوان خود را وارد نمایید" value={data.title}  onChange={(e)=>setData({...data,title: e.target.value})}/>
                             </Form.Group>
                        
                            <Form.Label>اولیت</Form.Label>
                            <Form.Select aria-label="Default select example" style={{ border:"none" }} name="priority" value={data.priority} onChange={(e)=>setData({...data,priority: e.target.value})}>
                                <option>اولیت خود را انتخاب کنید</option>
                                <option value="high">زیاد</option>
                                <option value="medium">متوسط</option>
                                <option value="low">کم</option>
                            </Form.Select>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>متن</Form.Label>
                                <Form.Control name="text" style={{ border:"none" }} required as="textarea" rows={3} value={data.text} onChange={(e)=>setData({...data,text: e.target.value})}/>
                            </Form.Group>
                            <button type="submit" style={{ marginRight: "88%" }} className="mb-3 ticket-btn btn btn-warning btn-block">ثبت تیکت</button>
                        </Form>
                    </div>
                </div>
            </Col>

            <div className="history">
              <div className="table_history">
                  <Table responsive>
                      <thead>
                      <tr>
                          <th>شماره</th>
                          <th>عنوان</th>
                          <th>اولیت</th>
                          <th>متن</th>
                          <th>وضعیت</th>
                      </tr>
                      </thead>
                      <tbody>
                      {ticket.map((u,key) =>(
                          <tr className="bg-table-custom">
                              <td>{key+1}</td>
                              <td>{u.title}</td>
                              <td>
                                  {u.priority}
                              </td>
                              <td>{u.text}</td>
                              <td>پاسخ داده شده</td>
                          </tr>
                      ))}
                      </tbody>
                  </Table>
              </div>
            </div>
        </>
    )
};

export default Ticket;