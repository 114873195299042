import React from "react";
import { Link } from "react-router-dom"
import './style/menu.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCamera, faDashboard} from '@fortawesome/free-solid-svg-icons';
import {faHeadset} from '@fortawesome/free-solid-svg-icons';
import {faUserGroup} from '@fortawesome/free-solid-svg-icons';
import {faSquarePollVertical} from '@fortawesome/free-solid-svg-icons';
import {faPenToSquare} from '@fortawesome/free-solid-svg-icons';
import {faRightFromBracket} from '@fortawesome/free-solid-svg-icons';
import {configAxios} from "./configAxios";
import {Routes, Route, useNavigate} from 'react-router-dom';
const  Menu = ()=>{
    const navigate = useNavigate();
   const HandelLogout = ()=>{
       configAxios.post('/logout').then(res=>{
           if (res.status === 200){
               localStorage.removeItem('token');
               navigate('/login');
           }
       }).catch((e)=>{

       });
   }
    return (
         <div className="menu_div">
             <ul>
                 <li>
                     <Link to="/">
                         <FontAwesomeIcon icon={faDashboard}/>
                         پیشخوان
                     </Link>
                 </li>
                 <li>
                     <Link to="/ticket">
                         <FontAwesomeIcon icon={faHeadset}/>
                         تیکت
                     </Link>
                 </li>
                 <li>
                     <Link to="/cart">
                         <FontAwesomeIcon icon={faHeadset}/>
                         خرید دوره
                     </Link>
                 </li>
                 <li>
                     <Link to="/Cash">
                         <FontAwesomeIcon icon={faUserGroup}/>
                          درآمدزایی
                     </Link>
                 </li>
                 <li>
                     <Link to="/invitation">
                         <FontAwesomeIcon icon={faUserGroup}/>
                         دعوت از دوستان
                     </Link>
                 </li>
                 <li>
                     <Link to="/Survey">
                         <FontAwesomeIcon icon={faSquarePollVertical}/>
                         نظرسنجی
                     </Link>
                 </li>
                 <li>
                     <Link to="/policy">
                         <FontAwesomeIcon icon={faPenToSquare}/>
                         قوانین و مقررات
                     </Link>
                 </li>
                 <li>
                     <Link to="https://blockquaint.com/">
                         <FontAwesomeIcon icon={faCamera} />
                         مشاهده وب سایت
                     </Link>
                 </li>
                 <li>
                     <Link to="/logout" onClick={HandelLogout}>
                         <FontAwesomeIcon icon={faRightFromBracket}/>
                         خروج
                     </Link>
                 </li>
             </ul>
         </div>
    )
}

export default Menu