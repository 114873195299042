import React, { useEffect, useState  } from "react";
import Header from "./Header";
import Col from "react-bootstrap/Col";
import {configAxios} from "./configAxios";

const RecovetyPassword = ()=>{
    const [data , setData] = useState({
        'email': '',
    })
    const [isLoading, setIsLoading] = useState(false)
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'origin': 'https://blockquaint.com',
        'Access-Control-Allow-Origin': '*'
      }
    const handleRecovery = async (e)=>{
        e.preventDefault();
        setIsLoading(true)
        configAxios.post('/send-otp',data,headers).then(res =>{
            if (res.status === 200) {
              setIsLoading(false)
              console.log(res);
            }
        }).catch((e)=>{
          setIsLoading(false)
                // alert(e.response.data)
        })
    }
   return (
       <>
           <div >
                <form onSubmit={handleRecovery} method="post"  style={{ marginRight:"36%" , marginTop:"10%" }}>
                    <div className="col-6 mt-5" >
                    <input type="email" className="form-control" value={data.email} onChange={(e)=>setData({...data,email: e.target.value})} placeholder="ایمیل خود را وارد نمایید..."/>
                    </div>
                    <button className="btn btn-success mt-2">ارسال کد</button>
                </form>
           </div>
       </>
   )
}

export default RecovetyPassword