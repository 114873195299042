import React ,{useEffect,useMemo,useState} from "react";
import Col from "react-bootstrap/Col"
import image from "./images/welcome.jpg";
import Form from "react-bootstrap/Form";
import {Routes, Route, useNavigate} from 'react-router-dom';
import Header from "./Header";
import './style/cart.css';
import { configAxios } from "./configAxios";
import Swal from "sweetalert2";
import axios from "axios";
import { Head, useForm,Link } from '@inertiajs/inertia-react';
import trc from "./images/trc.jpg";
import bep from "./images/bep.jpg";
const Cart = ()=>{
    
    const [getCart , setCart]=useState([]);
    const [getEpisode , setEpisode]=useState([]);
    const [isValue , setIsValue]=useState(false);
    useEffect(() => {
        
        if (!(localStorage.getItem('token'))) {
            navigate('/login')
        }
        
        if(getCart[0] == undefined){
            configAxios.get('/get-course').then(res=>{
                if(res.status === 200){
                setCart(res.data.course)
                }
            }).catch((e)=>{
            
            });
        }
        let course_id=document.getElementById('select_course');
        course_id.addEventListener('change',function(e){
                e.preventDefault();
                if(isNaN(e.target.value) === false){
                    configAxios.post('/getEpisode',{'course':e.target.value}).then(res =>{
                        setEpisode(res.data.data);
                        if(res.data.data[0]){ 
                        //    Swal.fire('سرفصل مورد نظر را انتخاب کنید')
                       document.getElementById('episode_id').removeAttribute('disabled')  
                        }else{
                        //    Swal.fire('سرفصلی یافت نشد!')
                           document.getElementById('episode_id').disabled = true
                        }
                   }).catch(e=>{

                   })
                }
         })
         episode_id.addEventListener('change',function(e){
            getEpisode.map((u)=>{
                if(u.id == e.target.value)
                document.getElementById('price_place').textContent=`قیمت : ${u.price}`;
                // console.log(u.price)
            })
         })
    });

    const [data , setData] = useState({
        'fullname':'',
        'course':'',
        'episode':'',
        'image':null,
        'texid':'',
        'email':''
    });

    const cardHandel = (e)=>{
        e.preventDefault();
        let fd  = new FormData;
        fd.append("fullname", data.fullname);
        fd.append('course',data.course);
        fd.append('image',data.image[0]);
        fd.append('texid',data.texid);
        fd.append('email',data.email);
        fd.append('episode',data.episode);
        axios({
            url: 'https://blockquaint.com/api/cart',
            method: 'post',
            data: fd,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
           }).then(res=>{
            if (res.status === 200){
                        Swal.fire('خرید دوره با موفقیت انجام شد')
                    }
           }).catch(e=>{
            Swal.fire('مشکلی رخ داده است')
           })  
    }

    let trc_20_value = document.getElementById('trc_20')
    let qr_trc_20 = document.getElementById('qr_trc_20')
    let bep_20_value = document.getElementById('bep_20')
    let qr_bep_20 = document.getElementById('qr_bep_20')
    const trc_20 = (event)=>{
        event.preventDefault();
        trc_20_value.classList.remove('d-none');
        trc_20_value.classList.add('d-flex');
        qr_trc_20.classList.remove('d-none');
        qr_trc_20.classList.add('d-block');

        qr_bep_20.classList.remove('d-block');
        qr_bep_20.classList.add('d-none');
        bep_20_value.classList.remove('d-flex');
        bep_20_value.classList.add('d-none');

    }
    const bep_20 = (event)=>{
        event.preventDefault();
        trc_20_value.classList.remove('d-flex');
        trc_20_value.classList.add('d-none');
        qr_trc_20.classList.remove('d-block');
        qr_trc_20.classList.add('d-none');

        qr_bep_20.classList.remove('d-none');
        qr_bep_20.classList.add('d-block');
        bep_20_value.classList.remove('d-none');
        bep_20_value.classList.add('d-flex');
    }
    function handelCopy(text){
        
        navigator.clipboard.writeText(text)
        // alert('کد معرف شما کپی شد')
        // setTimeout(()=>{
        //     setIsCopied(true)
        // },500)
    }
    function handelCopy_2(text){
        
        navigator.clipboard.writeText(text)
        // alert('کد معرف شما کپی شد')
        // setTimeout(()=>{
        //     setIsCopied(true)
        // },500)
    }
   return (
        <>
            <Header/>
           <div className="div_cart mt-5">
           <Form onSubmit={cardHandel} >
                    <div style={{ backgroundColor:"#1D1D1D",padding:"1rem" }}>
                         {/* course selected */}
                    <div className="name-pro">
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                {/* <Form.Label> نام و نام خانوادگی</Form.Label> */}
                                <Form.Control className="name_input" type="text" required  name="fullname" value={data.fullname} onChange={(e)=>setData({...data,fullname:e.target.value})} placeholder="نام و نام خانوادگی خود را وارد نمایید" />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            {/* <Form.Label>دوره مورد نظر را انتخاب کنید</Form.Label> */}
                            <Form.Select className="course_name" id="select_course" required aria-label="Default select example" name="course" value={data.course} onChange={(e)=>setData({...data,course:e.target.value})} >
                            <option>دوره مد نظر خود را انتخاب کنید</option>    
                            {getCart.map((u,key)=>(
                            
                                   <option value={u.id} key={key}>{u.title}</option>
                                ))}
                                
                            </Form.Select>
                        </Col>
                    </div>
                     {/* end course selected */}

                     {/* episode and price div */}
                    <Col sm={12} className="d-flex res-episode">
                    <Col sm={6} >
                            <Form.Select id="episode_id" required aria-label="Default select example" disabled name="course" value={data.episode} onChange={(e)=>setData({...data,episode:e.target.value})}>
                            <option>سرفصل مورد نظر خود را انتخاب کنید</option>    
                            {getEpisode.map((u,key)=>(
                            
                                   <option value={u.id} key={key}>{u.title}</option>
                                ))}
                                
                            </Form.Select>
                            
                           <div className="text-center" id="price_place" ></div>
                            <button className="mt-3" onClick={trc_20} style={{ width:"20%", backgroundColor:"#242424", color:"#F5A100", height:"40px",marginRight:"9%", borderRadius:"5px", border:"none" }}>
                                TRC20
                            </button>
                            <button className="mt-3" onClick={bep_20} style={{ width:"20%", backgroundColor:"#242424", color:"#F5A100", height:"40px",marginRight:"23%", borderRadius:"5px", border:"none" }}>
                                BEP20
                            </button>
                            <div className="d-flex" id="trc_20" style={{ width: "60%",marginRight: "9%",marginTop: "10px",color: "white",backgroundColor: "#242424",paddingRight: "9px"}}>
                              <div className="d-flex">
                              <div style={{ fontSize:"15px" }}>TLrhfBUanTwn9bFXkgDuKENdtqVY1TY55B</div>
                              <button onClick={handelCopy("TLrhfBUanTwn9bFXkgDuKENdtqVY1TY55B")} style={{ border:"none" , backgroundColor:"#F5A100",marginRight:"9%"}}>کپی کنید</button>
                              </div>
                            </div>
                            <div className="d-none" id="bep_20" style={{ width: "64%",marginRight: "9%",marginTop: "10px",color: "white",backgroundColor: "#242424",paddingRight: "9px"}}>
                             <div className="d-flex">
                             <div style={{ fontSize:"13px" }}>0x84669F6c33aBfFf90405060B58edF734Ea999A94</div>
                            <button onClick={handelCopy_2('0x84669F6c33aBfFf90405060B58edF734Ea999A94')} style={{ border:"none" , backgroundColor:"#F5A100"}}>کپی کنید</button>
                             </div>
                            </div>
                        </Col>
                        <Col sm={6} style={{ 'marginTop':"35px" }} >
                            <div className="qr_code d-flex" style={{ alignItems:"center" }}>
                                <div className="d-block" id="qr_trc_20">
                                <img src={trc} alt="" width={"200px"} height={"180px"} />
                                <p className="text-center" style={{ color: "#F5A100" }}>TRC 20</p>
                                </div>
                                <div className="d-none" style={{ marginRight:"10%" }} id="qr_bep_20">
                                    
                                <img src={bep} alt="" width={"200px"} height={"180px"} />
                                <p className="text-center" style={{ color: "#F5A100" }}>BEP 20</p>
                                </div>
                            </div>
                        </Col>
                    </Col>
                    <p style={{ color:"#F5A100" }}>مبلغ را انتقال دهید و فرم زیر را کامل کنید</p>
                    </div>
                     {/* end episode and price div */}
                     
                     <div className="mt-3" style={{ backgroundColor:"#1D1D1D",padding:"1rem" }}> 
                     <Col sm={12} className="email-file">
                       <Col sm={6}>
                        <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlInput1">
                            {/* <Form.Label>ایمیل </Form.Label> */}
                            <Form.Control required type="email" className="email" name="email" value={data.email} onChange={(e)=>setData({...data,email:e.target.value})} placeholder="ایمیل خود را وارد نمایید"/>
                        </Form.Group>
                       </Col>
                       <Col sm={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label id="shot_label">اسکرین شات </Form.Label>
                            <Form.Control type="file" required name="image" className="img_shot"  onChange={(e)=>setData({...data,image:e.target.files})}/>
                        </Form.Group>
                       </Col>
                    </Col>
                    
                    <Col sm={12} className="d-flex">
                        <Col sm={6}>  
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            {/* <Form.Label>TXID</Form.Label> */}
                            <Form.Control type="text" required name="txid" className="txid" value={data.texid} onChange={(e)=>setData({...data,texid:e.target.value})} placeholder="کد TXID خورد را وارد کنید"/>
                        </Form.Group>
                        </Col>
                        <Col sm={6} className="mt-4">
                             <Form.Group>
                             <Form.Label htmlFor="role" id="role_label">قوانین مقررات را مطالعه کرده ام و آن را میپذیرم.</Form.Label> 
                             {/* <Form.Check type="checkbox" required/> */}
                             </Form.Group>
                        </Col>
                    </Col>
                    
                    <button type="submit"  className="mb-3 btn submit-course btn-warning btn-block">خرید دوره</button>
                    </div>
                </Form>

               <div className="step_buy mt-3">
                     <span>مراحل خرید از سایت </span><br/> 
                     <p>لطفا تمام مراحل را با دقت سپری کنید ، مسئولیت پرداخت و واریز اشتباه بر عهده کار بر است و مجموعه بلک کوئینت هیچگونه مسئولیتی ندارد .</p>  
                     <span>مرحله اول</span><br/>
                     <span>انتخاب دوره</span><br/>
                     <p>در ابتدا سر فصل و دوره مورد نظر خود را انتخاب کنید .</p>
                     
                     <span>مرحله دوم</span><br/>
                     <span>انتخاب شبکه</span><br/>
                     <p>در این مرحله شبکه ای که تمایل دارید با آن انتقال را انجام دهید انتخاب کنید توجه داشته باشید که مسئولیت انتخاب شبکه اشتباه بر عهده کاربر است .</p>
                     
                     <span>مرحله سوم</span><br/>
                     <span>انتقال وجه</span><br/>
                     <p>در این قسمت مبلغ مورد نظر را با Gas Fee  جمع کرده و از طریق  QR Code ویا Public Key به مقصد مورد نظر انتقال دهید . </p>
                     
                     <span>مرحله چهارم</span><br/>
                     <span>ارسال اطلاعات </span><br/>
                     <p>پس از انتقال مبلغ کد TXID تراکنش را به همراه اسکرین شات پرداخت ارسال کنید.</p>
                     
                     <span>مرحله پنجم</span><br/>
                     <span>منتظر بمانید</span><br/>
                     <p>پس از ارسال و تکمیل فرایند منتظر بمانید تا اطلاعات ار سال شده بررسی شود این کار ممکن است تا 24 ساعت زمان ببرد لطفا شکیبا باشید .</p>
              </div> 
           </div>

        </>   
   )
}


export default Cart