import React, {useEffect, useState} from "react";
import Header from "./Header";
import Col from "react-bootstrap/Col"
import "./style/survey.css"
import image from "./images/survy.jpg"
import Form from "react-bootstrap/Form";
import {configAxios} from "./configAxios";
import Swal from "sweetalert2";
const Survey = () => {
    const [data , setData] = useState({
        'name':'',
        'priority':'',
        'text':'',
        'title':''
    });
   const survey = (e)=>{
      e.preventDefault();
      configAxios.post('/survey',data).then(res=>{
          if (res.status === 200) {
              // alert('نظر شما با موفقیت ثبت شد')
              Swal.fire('نظر شما با موفقیت ثبت شد');
          }
      }).catch((e)=>{
          // alert('مشکلی رخ داده است');
          Swal.fire('مشکلی رخ داده است');
      })
   }
    useEffect(() => {
        if (!(localStorage.getItem('token'))) {
            navigate('/login')
        }
    });
  return (
      <>
          <Header/>
          <div className="div_survey">
            <div className="banner-survey"><img src={image} width={'300px'} height={'376px'} alt=""/></div>
            <div className="form-survey">
                <Form onSubmit={survey}>
                    <div className="name-pro">
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>نام</Form.Label>
                                <Form.Control required type="text" name="name" placeholder="نام خود را وارد نمایید" value={data.name} onChange={(e)=>setData({...data,name: e.target.value})} />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Label>میزان رضایت</Form.Label>
                            <Form.Select aria-label="Default select example" name="priority" value={data.priority}onChange={(e)=>setData({...data,priority: e.target.value})}>
                                <option>انتخاب میزان رضایت</option>
                                <option value="high">زیاد</option>
                                <option value="medium">متوسط</option>
                                <option value="low">کم</option>
                            </Form.Select>
                        </Col>
                    </div>
                    <Col sm={12}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>عنوان تیکت</Form.Label>
                            <Form.Control required type="text" name="title" placeholder="عنوان خود را وارد نمایید"  value={data.title} onChange={(e)=>setData({...data,title: e.target.value})}/>
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>متن</Form.Label>
                            <Form.Control name="text" required as="textarea" rows={3} value={data.text} onChange={(e)=>setData({...data,text: e.target.value})} />
                        </Form.Group>
                    </Col>
                    <button type="submit" className="mb-3 btn btn-warning btn-block">ثبت نظر</button>
                </Form>
            </div>
          </div>
      </>
  )
};
export default Survey;
